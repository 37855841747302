import React, { useEffect, useState } from "react";
import { Wrapper } from "../styles";
import Breadcrumb from "../../../shared/Breadcrumbs";
import { Chip, Container, Box, Grid, Button } from "@mui/material";
import { getJiraProjectDetail } from "../../../httpRequests/jira";
import Loading from "../../../shared/Loading";
import Flags from "../../../shared/Flags";
import statusChip from "../../../utils/statusChip";
import { makeStyles } from "@material-ui/core/styles";
import {
  BookOpenIcon,
  UsersIcon,
  RocketLaunchIcon,
  BanknotesIcon,
  CodeBracketIcon,
  CalendarDaysIcon,
  PencilIcon,
  LinkIcon,
  CloudIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import JiraDescription from "../shared/JiraDescription";
import JiraLatestComment from "../../../shared/JiraLatestComment";
import { formatDate } from "../../../utils/checkDate";
import ProgressBars from "../../../shared/ProgressBar";

const useStyles = makeStyles(() => ({
  svg: {
    position: "relative",
    top: 0,
    marginRight: 5,
    width: 18,
  },
  edit: {
    width: 20,
  },
}));

const fields =
  "customfield_10665,customfield_10666,customfield_10668,customfield_11777,customfield_11785,customfield_11780,customfield_11778,customfield_10669,customfield_10670,customfield_11781,customfield_10675,customfield_11782,customfield_10679,customfield_10663,customfield_10680,customfield_10681,customfield_10015,customfield_10683,customfield_11779,summary,status,assignee,duedate,description,comment";

const PMOProjectDetail = ({ id }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await getJiraProjectDetail(id, fields);
        setData(res.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const { fields: projectFields } = data || {};
  const {
    summary,
    customfield_10666,
    customfield_10665,
    customfield_10678,
    customfield_10670,
    customfield_11781,
    customfield_11782,
    customfield_11779,
    customfield_10669,
    customfield_10675,
    customfield_10679,
    customfield_10663,
    customfield_10680,
    customfield_10681,
    customfield_10015,
    customfield_11777,
    customfield_11778,
    customfield_11780,
    customfield_11785,
    customfield_10668,
    duedate,
  } = projectFields || {};

  return (
    <Container className="container">
      {loading ? (
        <Loading />
      ) : (
        <Wrapper>
          <div className="page-header">
            <h2>Project Detail</h2>
            <div className="flex">
              <Breadcrumb
                page="Detail"
                link={`project-details/pmo?id=${id}`}
                pmo
              />
            </div>
            <div className="link-to-jira">
              <a
                href={`https://lotusretails.atlassian.net/browse/${data.key}`}
                target="_blank"
              >
                <Button>
                  <LinkIcon />
                  View Issue on Jira
                </Button>
              </a>
            </div>
          </div>
          <div className="project-detail">
            <div className="top-section">
              <div className="percent-completed">
                <ProgressBars
                  data={{
                    label: "Percent Completed",
                    percent: customfield_10668
                      ? parseInt(customfield_10668?.value.replace("%", ""), 10)
                      : "",
                    color: "#0071cd",
                  }}
                />
              </div>

              <h2>{summary}</h2>
              <div className="flex top">
                <h2 className="flex">
                  {customfield_10666 && (
                    <Chip
                      className="domain"
                      color="error"
                      label={customfield_10666.value}
                      size="small"
                    />
                  )}

                  {customfield_10675 &&
                    customfield_10675?.value !== "Finance" && (
                      <Chip
                        className="domain"
                        color="error"
                        label={customfield_10675?.value}
                        size="small"
                      />
                    )}

                  {customfield_10665 && (
                    <Flags data={customfield_10665.value} pmo />
                  )}
                </h2>
                <div className="flex">
                  {customfield_10678 && (
                    <p className="risk">
                      Risk Status:{" "}
                      {statusChip(
                        customfield_10678?.value.replace(/\(.*?\)/g, "").trim()
                      )}
                    </p>
                  )}

                  {statusChip(customfield_10670?.value)}
                </div>
              </div>

              <p className="tpm">
                <UsersIcon className={classes.svg} />
                PM:{" "}
                {(customfield_11781 && customfield_11781[0]?.displayName) ||
                  "N/A"}
                <span>
                  <UsersIcon className={classes.svg} />
                  PO:{" "}
                  {(customfield_11782 && customfield_11782[0]?.displayName) ||
                    "N/A"}
                </span>
              </p>
            </div>

            <div className="detail-container">
              {customfield_11785 && (
                <h3 className="released">
                  <span>
                    <RocketLaunchIcon /> <b>Release:</b>{" "}
                    {moment(customfield_11785).format("DD MMMM YYYY")}
                  </span>
                </h3>
              )}

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={7}>
                    <div className="summary-item">
                      <div className="flex">
                        <h4 className="no-border">
                          <BookOpenIcon /> Project Detail
                        </h4>
                        <div className="flex no-border type">
                          <h4 className="uppercase no-border">
                            {customfield_10669?.value || ""}
                          </h4>
                        </div>
                      </div>

                      <div className="detail-item no-border">
                        <div className="jira-description pmo">
                          <h5>Description</h5>
                          <JiraDescription
                            data={projectFields?.description?.content || []}
                          />

                          <h5>
                            Prioritization Scale (Complexity, Business Impact):{" "}
                            <span>{customfield_10679?.value || ""}</span>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <div className="summary-item">
                      <h4>
                        <BanknotesIcon /> Budget Summary
                      </h4>
                      <div className="detail-item flex no-border">
                        <div className="item pmo ontrack">
                          <p>Budget Code</p>
                          <h3>{customfield_10663 || "N/A"}</h3>
                        </div>
                        <div className="item pmo inprogress">
                          <p>Budget - IT CAPEX (mB)</p>
                          <h3>{customfield_10680 || 0}</h3>
                        </div>
                        <div className="item pmo at-risk">
                          <p>Budget - Others CAPEX</p>
                          <h3>{customfield_10681 || 0}</h3>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <div className="summary-item">
                      <h4>
                        <CloudIcon /> Hosting
                      </h4>
                      <div className="detail-item flex no-border centered">
                        <h3>{customfield_11780?.value || "N/A"}</h3>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <div className="summary-item">
                      <h4>
                        <CodeBracketIcon /> Develop team (team name, vendor
                        name)
                      </h4>
                      <div className="detail-item flex no-border pmo-development">
                        <div className="item pmo ontrack">
                          <p>Development</p>
                          <h3>{customfield_11777?.value || "N/A"}</h3>
                        </div>

                        <div className="item team">
                          <h5>Team(s):</h5>
                          <p>{customfield_11778 || "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className="summary-item">
                      <h4>
                        <CalendarDaysIcon /> Plan
                      </h4>
                      <div className="detail-item flex no-border">
                        <div className="item border">
                          <p>Start Date</p>
                          <h3>{formatDate(customfield_10015)}</h3>
                        </div>
                        <div className="item border">
                          <p>Due Date</p>
                          <h3>{formatDate(duedate)}</h3>
                        </div>
                        <div className="item border">
                          <p>Deploy Date</p>
                          <h3>{formatDate(customfield_11779)}</h3>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className="summary-item">
                      <h4>
                        <PencilIcon /> Note
                      </h4>
                      <div className="detail-item no-border">
                        <JiraLatestComment data={data} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
        </Wrapper>
      )}
    </Container>
  );
};

export default PMOProjectDetail;
